import Counter from "@/components/Counter";
import { LocalConfig } from "@/config";
import Realistic from "react-canvas-confetti/dist/presets/realistic";
import { Popup } from "react-vant";
import style from "./style.less";

const InstallBonusPopup = ({ amount, showPopup, onClose, isInstall }) => {
  const onInit = ({ conductor }) => {
    // setConductor(conductor);
    conductor.shoot();
  };
  return (
    <Popup
      visible={showPopup}
      position="center"
      closeOnClickOverlay={true}
      closeable={false}
      style={{ background: "rgba(0,0,0,0.7)", height: "100%" }}
      destroyOnClose={true}
      onClose={() => {
        onClose && onClose();
      }}
    >
      <Realistic onInit={onInit} />
      <div class={style.page_container}>
        <img class={style.congratulation_png} src={require("@/assets/images/home/congratulation.png").default} />
        <img class={style.money_png} src={require("@/assets/images/home/money.png").default} />
        <div class={style.you_got}>You Got</div>
        <div class={style.amount}>
          <Counter counts={amount} /> {LocalConfig.currencyCode}
        </div>
        <div class={style.bonus_desc}>{isInstall ? "Bonus for installing game" : "Game rewards have been issued to wallets"}</div>
        <div
          class={style.button}
          onClick={() => {
            onClose && onClose();
          }}
        >
          OK
        </div>
      </div>
    </Popup>
  );
};

export default InstallBonusPopup;
